import { template as template_c7f2ffd0aaeb4f3999ba24246872648f } from "@ember/template-compiler";
const WelcomeHeader = template_c7f2ffd0aaeb4f3999ba24246872648f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
