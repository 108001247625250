import { template as template_98bb085c49e74f569a51798722eac672 } from "@ember/template-compiler";
const SidebarSectionMessage = template_98bb085c49e74f569a51798722eac672(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
