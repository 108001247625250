import { template as template_d9083fc4ea4247d3b247da216e0ae88b } from "@ember/template-compiler";
const FKLabel = template_d9083fc4ea4247d3b247da216e0ae88b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
