import { template as template_619ad3b991fc4ca5a597ca47deb472e8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_619ad3b991fc4ca5a597ca47deb472e8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
