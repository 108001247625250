import { template as template_73d4c2dff4a44f6d8d3a7809fe7aa70e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_73d4c2dff4a44f6d8d3a7809fe7aa70e(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
